import store from '@/store'
import Vue from 'vue'
import XEUtils from 'xe-utils'

// 是否有权限
let hasControl = (list) => {
  if (store.getters.getLoginAccount.isAdmin) return true // 管理员
  if (!list || !list.length) return true // 没传入权限数据
  let actionKeyList = store.getters.getLoginAccount.actionKeyList
  let result = list.some((item) => {
    return actionKeyList.some((key) => {
      return key.indexOf(`/admin${item}`) > -1 || key.indexOf(`/admin/gcvip${item}`) > -1
    })
  })
  return result
}

// 列表累加总计
let listAddTotal = (list, field) => {
  let listCount = list.map((item) => {
    return field ? item[field] : item
  })
  return XEUtils.sum(listCount)
}

// 表格选择提示
let tableChoosePrompt = () => {
  Vue.prototype.$message.error('请先选择表格数据项，再进行操作')
}

// 自动生成
let autoGenerate = (val) => {
  return val || '自动生成'
}

/**
 * 获取合并单元格配置列表
 * @params {Array} tableData 表格数据
 * @params {Array} tableColumn 表格列
 * @params {String} fieldMatch 合并字段匹配
 * @returns {Array} result 合并结果
 *
 */
let getMergeCellConfigList = (tableData, tableColumn, fieldMatch) => {
  let result = [] // 合并-单元格
  let mergeList = [] // 合并-列表
  let mergeObj = null // 合并-项
  let mergeIdx = null // 合并-索引
  let mergeLen = null // 合并-行数
  tableData.forEach((rowItem, rowIndex) => {
    if (mergeObj) {
      if (mergeObj[fieldMatch] === rowItem[fieldMatch]) {
        mergeLen++
        if (rowIndex === tableData.length - 1) {
          mergeList.push({
            idx: mergeIdx,
            len: mergeLen
          })
        }
      } else {
        if (mergeLen > 1) {
          mergeList.push({
            idx: mergeIdx,
            len: mergeLen
          })
        }
        mergeObj = rowItem
        mergeIdx = rowIndex
        mergeLen = 1
      }
    } else {
      mergeObj = rowItem
      mergeIdx = rowIndex
      mergeLen = 1
    }
  })
  mergeList.forEach((mergeItem) => {
    tableColumn.forEach((colItem, colIndex) => {
      if (colItem.isMerge) {
        let obj = {
          row: mergeItem.idx, // 单元格-行位置
          col: colIndex, // 单元格-列位置
          rowspan: mergeItem.len, // 合并-行数
          colspan: 1 // 合并-列数
        }
        result.push(obj)
      }
    })
  })
  return result
}

let tool = {
  hasControl,
  tableChoosePrompt,
  autoGenerate,
  getMergeCellConfigList,
  listAddTotal
}

export default tool
