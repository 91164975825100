<template>
  <div id="app">
    <LayContainer v-if="islogin">
      <router-view :key="$route.fullPath"></router-view>
    </LayContainer>
    <router-view v-else :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import LayContainer from '@/components/layout/lay-container'
export default {
  components: { LayContainer },
  data() {
    return {
      islogin: false
    }
  },
  watch: {
    '$route.path': {
      handler: function (val) {
        // 处理路由参数变化后的操作
        if (val == '/login') {
          this.islogin = false
        } else {
          this.islogin = true
        }
      }
    }
  },
  created() {
    this.findRoleVersion()
    if (
      localStorage.getItem('runTimeTest') == 'true' ||
      localStorage.getItem('runTimeTest') == true
    ) {
      this.$store.dispatch('asyncUpdateRunTimeTest', true)
    } else {
      this.$store.dispatch('asyncUpdateRunTimeTest', false)
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyDown)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown)
  },
  methods: {
    // 每次刷新，重新读取用户权限
    findRoleVersion() {
      this.$api({
        method: 'post',
        url: '/admin/roleVersion',
        data: {
          roleVersion: this.$store.getters.getRoleVersion
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          if (res.data.roleVersion != null && res.data.account != null) {
            this.$store.dispatch('asyncUpdateRoleVersion', res.data.roleVersion)
            this.$store.dispatch('asyncUpdateLoginAccount', res.data.account)
          }
        }
      })
    },
    // 解决一些浏览器按Backspace键返回页面问题，如360浏览器等
    handleKeyDown(event) {
      let isStop =
        event.key === 'Backspace' &&
        document.activeElement.tagName !== 'INPUT' &&
        document.activeElement.tagName !== 'TEXTAREA'
      if (isStop) event.preventDefault()
    }
  }
}
</script>

<style lang="less">
// default start-------------------------------------------
html,
body,
#app {
  height: 100%;
}
body {
  margin: initial;
  font-size: 12px;
  .scroll-bar();

  input::-webkit-input-placeholder {
    -webkit-text-fill-color: #ccc; // 占位符颜色
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none; // 数字输入框去除上下箭头
    margin: 0;
  }
}

.vxe-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 10px 0;
  .vxe-tools--operate {
    margin-left: 10px;
  }
}
// default end-------------------------------------------

// element-ui start-------------------------------------------
.el-link {
  font-size: 12px;
}
.el-radio {
  margin-right: 20px;
}
.el-textarea__inner {
  padding: 5px;
  .scroll-bar();
}
.el-button--mini {
  padding: 7px;
}
.el-form-item {
  margin-bottom: 0;
}
.el-form-item--mini.el-form-item {
  margin-bottom: 10px;
}
.el-form-item__error {
  position: initial;
  padding-top: 5px;
}

.el-button + .el-button,
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 8px;
}

// 弹窗
.el-dialog {
  margin-top: 82px !important;
  .scroll-bar();
  .el-dialog__body {
    padding: 10px 20px;
  }
  .el-dialog__title {
    font-size: 16px;
  }
}

// 选项卡
.el-tabs {
  .el-tabs__item {
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    &.is-active {
      font-weight: bold;
    }
  }
  .el-tabs__header {
    margin-bottom: 10px;
  }
}

// 输入框
.el-input {
  &.el-input--prefix {
    .el-input__inner {
      padding-left: 30px;
    }
  }
  .el-input__inner {
    font-size: 12px;
    padding: 0 5px;
  }
  .is-disabled .el-input__inner {
    color: #666; // 输入框禁用颜色
  }
}

// 下拉选择
.el-select {
  .el-input__inner {
    padding-right: initial;
    font-size: 12px;
  }
}
.el-select-dropdown__item {
  padding: 0 5px;
  font-size: 12px;
  color: @colorBlack;
  &.selected {
    font-weight: normal;
  }
}

// 下拉列表
.el-dropdown {
  color: @colorBlack;
}
.el-dropdown-menu__item,
.el-menu-item {
  font-size: 12px;
}

// 日期组件
.el-picker-panel [slot='sidebar'],
.el-picker-panel__sidebar {
  width: 80px; // 左侧宽度
}
.el-picker-panel [slot='sidebar'] + .el-picker-panel__body,
.el-picker-panel__sidebar + .el-picker-panel__body {
  margin-left: 80px; // 内容左侧边距
}
.el-picker-panel__shortcut {
  line-height: 24px; // 日期左侧快捷方式
}

.el-autocomplete-suggestion {
  &.popper-width-auto {
    min-width: 80px;
    width: auto !important;
  }
  li {
    // 自助筛选输入框-下拉数据
    padding: 5px 15px 5px 5px;
    line-height: 18px;
    font-size: 11px;
    & ~ li {
      border-top: 1px dashed #ddd;
    }
  }
}
.el-message-box__message p {
  word-break: break-all;
}
// element-ui end-------------------------------------------

// vxe-table start-------------------------------------------
.vxe-table--header-wrapper {
  color: @colorBlack!important;
}
.vxe-table--body-wrapper {
  .body--wrapper {
    min-height: 30px !important;
  }
}
.vxe-table--header-wrapper,
.vxe-table--body-wrapper,
.vxe-table--footer-wrapper {
  .scroll-bar();
  color: @colorBlack;
}
.vxe-table--render-default .vxe-cell {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

// 表格头部和内容样式设置
.vxe-table--render-default.size--mini .vxe-body--column:not(.col--ellipsis),
.vxe-table--render-default.size--mini .vxe-footer--column:not(.col--ellipsis),
.vxe-table--render-default.size--mini .vxe-header--column:not(.col--ellipsis) {
  height: 38px;
  padding: 5px 0; // 表头填充
}
.vxe-table--render-default {
  .vxe-header--column {
    vertical-align: top;
    line-height: 1.5;
    .vxe-icon-edit {
      color: @colorBlue; // 编辑图标
    }
  }
  .vxe-footer--row {
    background: #fff9ef;
  }
}

// 表格分页样式设置
.vxe-grid--pager-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  .vxe-pager {
    height: 28px !important;
    font-size: 12px !important;
    color: @colorBlack;
    .vxe-pager--sizes {
      margin-left: 0;
    }
    .vxe-input--inner {
      text-align: left !important;
    }
    .vxe-input,
    .vxe-input--inner,
    .vxe-pager--jump-prev,
    .vxe-pager--prev-btn,
    .vxe-pager--num-btn,
    .vxe-pager--next-btn,
    .vxe-pager--jump-next,
    .vxe-pager--goto {
      height: 28px !important;
      line-height: 28px !important;
      font-size: 12px !important;
      color: @colorBlack;
    }
  }
}

// 按钮缩进
.vxe-button + .vxe-button--dropdown,
.vxe-button + .vxe-button-wrapper,
.vxe-button + .vxe-button.type--button,
.vxe-button-wrapper + .vxe-button--dropdown,
.vxe-button-wrapper + .vxe-button-wrapper,
.vxe-button-wrapper + .vxe-button.type--button,
.vxe-input + .vxe-button--dropdown,
.vxe-input + .vxe-button-wrapper,
.vxe-input + .vxe-button.type--button {
  margin-left: 8px;
}
// vxe-table end-------------------------------------------;
</style>
