<template>
  <div class="box-form">
    <el-form
      ref="refForm"
      :model="formData"
      :rules="rules"
      :size="formConfig.size"
      :disabled="disabled"
    >
      <el-tooltip v-for="(item, index) in column" :key="index" placement="top" :open-delay="500">
        <div slot="content" v-html="item.title" />
        <el-form-item
          :label="item.title"
          :prop="isRules ? item.field : ''"
          :required="item.required"
          :style="{ width: item.width || formConfig.width || '240px' }"
        >
          <!-- input -->
          <el-input
            v-if="isComponentInput(item)"
            v-model="formData[item.field]"
            :ref="item.field"
            :type="item.type"
            :disabled="item.disabled"
            :placeholder="item.placeholder || `请输入${item.title}`"
            clearable
            @input="fnChange(item)"
          >
            <span v-if="item.prefix" slot="prefix">{{ item.prefix }}</span>
            <span v-if="item.suffix" slot="suffix">{{ item.suffix }}</span>
          </el-input>

          <!-- date-picker -->
          <e-date-picker
            v-if="isComponentDate(item)"
            width="100%"
            :ref="item.field"
            :type="item.type"
            :placeholder="item.placeholder || `请输入${item.title}`"
            :format="item.format"
            :value-format="item.valueFormat"
            :value.sync="formData[item.field]"
            clearable
            @change="fnChange(item)"
          />
          <!-- <el-date-picker
            v-if="isComponentDate(item)"
            v-model="formData[item.field]"
            :ref="item.field"
            :type="item.type"
            :placeholder="item.placeholder || `请输入${item.title}`"
            :format="item.format"
            :value-format="item.valueFormat"
            :disabled="item.disabled"
          /> -->

          <!-- select -->
          <el-select
            v-if="item.type === 'select'"
            v-model="formData[item.field]"
            :ref="item.field"
            :placeholder="item.placeholder || '请选择'"
            :disabled="item.disabled"
            :remote="item.remote"
            :remote-method="item.remoteMethod"
            filterable
            clearable
            @change="fnChange(item)"
          >
            <el-option v-if="item.emptyDo && item.emptyDo.title" label="" value="" disabled>
              <el-link
                type="success"
                style="display: block"
                :underline="false"
                @click="item.emptyDo.fn"
              >
                {{ item.emptyDo.title }}
              </el-link>
            </el-option>
            <el-option
              v-for="(optionItem, optionIndex) in item.options"
              :key="optionIndex"
              :label="optionItem.key"
              :value="optionItem.val"
              :disabled="optionItem.disabled"
              class="flex flex-y-center"
            >
              <span class="flex-1">{{ optionItem.key }}</span>
              <el-link
                v-if="item.optionDo && item.optionDo.title"
                class="m-l-10 l-h-1"
                type="primary"
                @click="item.optionDo.fn(optionItem)"
              >
                {{ item.optionDo.title }}
              </el-link>
            </el-option>
            <template #empty>
              <div style="text-align: center">
                <div
                  style="
                    padding: 10px 0;
                    margin: 0;
                    text-align: center;
                    color: #999;
                    font-size: 14px;
                  "
                >
                  无匹配数据
                </div>
                <el-link
                  v-if="item.emptyDo && item.emptyDo.title"
                  class="m-y-10"
                  type="primary"
                  @click="item.emptyDo.fn"
                >
                  {{ item.emptyDo.title }}
                </el-link>
              </div>
            </template>
          </el-select>

          <!-- radio -->
          <el-radio-group
            v-if="item.type === 'radio'"
            v-model="formData[item.field]"
            :disabled="item.disabled"
          >
            <el-radio
              v-for="(item, index) in item.options"
              :key="index"
              :label="item.val"
              :disabled="item.disabled"
            >
              {{ item.key }}
            </el-radio>
          </el-radio-group>

          <!-- checkbox -->
          <el-checkbox
            v-if="item.type === 'checkbox'"
            v-model="formData[item.field]"
            :disabled="item.disabled"
          >
            {{ item.placeholder }}
          </el-checkbox>

          <!-- switch -->
          <el-switch
            v-if="item.type === 'switch'"
            v-model="formData[item.field]"
            :disabled="item.disabled"
            :active-text="formData[item.field] ? item.activeText : item.inActiveText"
          >
            {{ formData[item.field] }}
          </el-switch>

          <!-- upload -->
          <e-upload-image
            v-if="item.type === 'upload' && formData[item.field]"
            :list.sync="formData[item.field]"
            :size="item.size"
            :limit="item.limit"
            :disabled="disabled || item.disabled"
            :isDelete="item.isDelete"
          />

          <!-- txt -->
          <span v-if="item.type === 'txt'" class="txt">{{ formData[item.field] }}</span>

          <!-- prompt -->
          <span v-if="item.type === 'prompt'" class="prompt">{{ formData[item.field] }}</span>
        </el-form-item>
      </el-tooltip>
      <!-- <el-form-item
        v-if="$slots.default"
        label=" "
        class="operate"
      >
        <slot></slot>
      </el-form-item> -->
      <div v-if="$slots.default">
        <slot></slot>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    column: {
      type: Array,
      default: () => {
        return []
        /**
         * type 类型(text/number/textarea || select || radio || checkbox || switch || date/daterange/datetime)
         * field 标签值字段
         * title 标签名
         * placeholder 占位符
         * required 是否必填
         * disabled 是否禁用
         *
         * 输入框
         * prefix 前缀
         * suffix 后缀
         *
         * 日期
         * format 日期格式
         * valueFormat 返回日期格式
         *
         * 下拉
         * options 下拉选项列表
         */
      }
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rules: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {}
    }
  },
  computed: {
    formConfig() {
      let obj = {
        inline: true,
        size: 'mini'
      }
      let result = { ...obj, ...this.config }
      return result
    },
    isRules() {
      return JSON.stringify(this.rules) !== '{}'
    },
    formDisplay() {
      let result = this.formConfig.inline ? 'flex' : 'block'
      return result
    },
    labelWidth() {
      return this.formConfig.inline ? 'auto' : '120px'
    },
    contentMarginRight() {
      return this.formConfig.inline ? '10px' : '0'
    }
  },
  watch: {
    data: {
      handler(val) {
        if (JSON.stringify(this.formData) !== JSON.stringify(val)) {
          this.formData = this.$util.deepCopy(val)
        }
      },
      deep: true,
      immediate: true
    },
    formData: {
      handler(val) {
        this.$emit('update:data', this.$util.deepCopy(val))
      },
      deep: true
    }
  },
  methods: {
    isComponentInput(item) {
      let result = item.type === 'text' || item.type === 'textarea' || item.type === 'number'
      return result
    },
    isComponentDate(item) {
      let result =
        item.type === 'date' ||
        item.type === 'datetime' ||
        item.type === 'daterange' ||
        item.type === 'datetimerange'
      return result
    },
    fnChange(item) {
      this.$nextTick(() => {
        if (item.fnChange) item.fnChange()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box-form {
  .txt {
    color: @colorGrayDeep;
  }
  .prompt {
    color: @colorGrayLight;
  }
  .operate {
    display: block;
  }

  /deep/ .el-form {
    display: v-bind(formDisplay);
    flex-wrap: wrap;

    // 设置表单内部组件宽度
    .el-input,
    .el-select {
      width: 100%;
    }
    .el-radio__label {
      padding-left: 0;
    }

    .el-checkbox__label {
      padding-left: 5px;
      font-size: 12px;
    }

    .el-switch__label * {
      font-size: 12px;
      color: #666;
    }

    .el-form-item {
      display: flex;
      align-items: flex-start;
      &.focusing {
        // background: #e6f7ff;
        outline: none;
        border-radius: 4px;
      }
    }

    // 设置label省略号
    .el-form-item__label {
      max-width: 120px;
      width: v-bind(labelWidth);
      padding-right: 5px;
      font-size: 12px;
      color: @colorBlack;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .el-form-item__content {
      flex: 1;
      line-height: 26px;
      margin-right: v-bind(contentMarginRight);
    }

    .el-range__icon {
      line-height: 22px;
    }

    .el-range-separator {
      line-height: 20px;
    }
  }
}
</style>
